import React, { useState } from 'react'
import { useStaticQuery } from "gatsby"
import Layout from '../components/layout'
import Footer from '../components/footer'
import Intro from '../components/intro'
import Swiper from 'react-id-swiper'
import ibBg from '../assets/img/in-bg.jpg'

const TitlesPage = () => {

  const data = useStaticQuery(graphql`
    query {
        allContentfulIndustriebouwSlider(sort: {order: DESC, fields: createdAt}) {
            edges {
              node {
                link
                ImgCenter {
                  file {
                    url
                  }
                }
                ImgRight {
                  file {
                    url
                  }
                }
                imgLeft {
                  file {
                    url
                  }
                }
              }
            }
          }
          allContentfulSportLeisureSlider(sort: {order: DESC, fields: createdAt}) {
            edges {
              node {
                link
                imgCenter {
                  file {
                    url
                  }
                }
                imgLeft {
                  file {
                    url
                  }
                }
                imgRight {
                  file {
                    url
                  }
                }
              }
            }
          }
    }
  `)

  const params2 = {
    speed: 1200,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '">' + (index + 1) + '</span>';
      },
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
  }

  const [active, setActive] = useState(true);

  const showIndustriebouw = function () {
    setActive(true);
    console.log(active)
  }

  const showSport = function () {
    setActive(false);
    console.log(active)
  }

  return (
    <Layout>
      <Intro title="Onze titels" text="Al onze kennismagazines zijn toegespitst op specifieke segmenten én doelgroepen. Op dit moment bestaat onze portfolio uit Industriebouw (gericht op bouwprojecten in allerlei sectoren) en Sport & Leisurebouw (gericht op sport & leisure accommodaties). De magazines bieden adverteerders uitstekende mogelijkheden om doelgericht in te spelen op hun doelgroep." />
      <div className="slider-container">
        <div className="inner">
          <div className={active ? "slider-buttons-wrapper" : "slider-buttons-wrapper show"}>
            <div className="slider-button" onClick={showIndustriebouw} onKeyDown={showIndustriebouw}>
              <span>Industriebouw</span>
            </div>
            <div className="slider-button" onClick={showSport} onKeyDown={showSport}>
              <span>GWWInfra</span>
            </div>
          </div>
        </div>
        <div className={active ? "slider-wrapper" : "slider-wrapper show"}>
          <div className="slider-content" style={{ backgroundImage: `url(${ibBg})` }}>
            <Swiper {...params2}>
              {data.allContentfulIndustriebouwSlider.edges.map((edge, i) => {
                return (
                  <div className="slider-images" key={i}>
                    <a href={edge.node.link} target="_blank" rel="noreferrer">
                      <div className="mock-up">
                        <img src={edge.node.imgLeft.file.url} alt=""/>
                        <img src={edge.node.ImgCenter.file.url} alt=""/>
                        <img src={edge.node.ImgRight.file.url} alt=""/>
                      </div>
                    </a>
                  </div>
                )
              }
              )}
            </Swiper>
          </div>
          <div className="slider-content" style={{ backgroundImage: `url(${ibBg})` }}>
            <Swiper {...params2}>
              {data.allContentfulSportLeisureSlider.edges.map((edge, i) => {
                return (
                  <div className="slider-images" key={i}>
                    <a href={edge.node.link} target="_blank" rel="noreferrer">
                      <div className="mock-up">
                        <img src={edge.node.imgLeft.file.url} alt=""/>
                        <img src={edge.node.imgCenter.file.url} alt=""/>
                        <img src={edge.node.imgRight.file.url} alt=""/>
                      </div>
                    </a>
                  </div>
                )
              }
              )}
            </Swiper>
          </div>
        </div>
      </div>
      <Footer />
    </Layout >
  )
}

export default TitlesPage;
