import React from 'react'
import { Link } from "gatsby"

const Footer = () => {
    return (
        <footer>
            <div className="inner">
                <div className="footer-wrapper">
                    <ul>
                        <li><Link to="/home">Home</Link></li>
                        <li><Link to="/about">Wie zijn wij</Link></li>
                        <li><Link to="/titles">Onze titels</Link></li>
                        <li><Link to="/subscriptions">Mediabureau</Link></li>
                        <li><Link to="/onze-opdrachtgevers">Onze opdrachtgevers</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                    </ul>
                    <span>{new Date().getFullYear()} ©Work4Media</span>
                </div>
            </div>

        </footer>
    )
}

export default Footer;