import React from 'react'

const Intro = (props) => {
    return (
        <div className="intro-wrapper">
            <div className="intro-content">
                <div className="inner">
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <h3>{props.title}</h3>
                            <p className="flat-text">{props.text}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Intro;
